import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = ({ loading }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pathname]);

  useEffect(() => {
    if (loading) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [loading]);

  return null;
};

export default ScrollToTop;
