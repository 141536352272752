import React, { Suspense } from 'react';
import { useMediaQuery } from 'react-responsive';
import ThirdStep from '../assets/thirdtStep.js'
import Footer from '../layouts/footer.js'

const HeaderMobile = React.lazy(() => import('../layouts/headerMobile'));
const HeaderComputer = React.lazy(() => import('../layouts/headerComputer'));



function Livraison() {
  const isMobile = useMediaQuery({ maxWidth: 960 });
  let header;

  if (isMobile) {
    header = <HeaderMobile />;
  } else {
    header = <HeaderComputer />;
  }

  return (
      <>
        <Suspense fallback={<div className='header-chargement'></div>}>
          {header}
        </Suspense>

        <ThirdStep />

        <Footer />
      </>
  );
}

export default Livraison;